<template>
      <div id="app antialiased">
            <header class="relative z-10 pb-2 md:pb-8 flex flex-wrap items-start justify-between">
                  <div class="w-full md:w-auto mb-2">
                  <router-link class="btn logo " :to="{ name: 'home' }">{{
                  site.title
                  }}</router-link>
                  </div>
                  <navigation></navigation>
            </header>
            <transition name="fade" mode="out-in">
                  <router-view />
            </transition>
      </div>
</template>

<script>
import { mapState } from "vuex";
const Navigation = () => import(/* webpackChunkName: "Navigation" */ "@/components/Navigation.vue");
export default {
      name: "App",
      metaInfo() {
            return {
                  titleTemplate: "sources of die neue typographie" + " %s",
            };
      },
      components: {
            Navigation,
      },
      computed: mapState(["site"]),
      created() {
            this.$store.dispatch("loadSite");
      },
};
</script>
