import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior(savedPosition) {
      if (savedPosition) {
            return savedPosition;
      } else {
            return { x: 0, y: 0 };
      }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "Home" */ "./views/Home.vue"),
      beforeEnter: (to, from, next) => {
        if (store.state.home.length === 0) {
          store.dispatch("loadHome").then(next);
        } else {
          next();
        }
      },
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "About" */ "./views/About.vue"),
      beforeEnter: (to, from, next) => {
        if (store.state.about.length === 0) {
          store.dispatch("loadAbout").then(next);
        } else {
          next();
        }
      },
    },
    {
      path: "/addresses",
      name: "addresses",
      component: () =>
        import(/* webpackChunkName: "Addresses" */ "./views/Addresses.vue"),
      beforeEnter: (to, from, next) => {
        if (store.state.addresses.length === 0) {
          store.dispatch("loadAddresses").then(next);
        } else {
          next();
        }
      },
    },
    {
            path: "/conference",
            name: "conference",
            component: () => import(/* webpackChunkName: "Conference" */ "./views/Conference.vue"),
            beforeEnter: (to, from, next) => {
                  if (store.state.conference.length === 0) {
                        store.dispatch("loadConference").then(next);
                  } else {
                        next();
                  }
            },
            children: [
                  {
                        path: ":id",
                        props: true,
                        name: "video",
                        component: () =>
                        import(/* webpackChunkName: "Video" */ "./views/Video.vue"),
                  }
            ]
    },
    {
      path: "/exhibitions",
      name: "exhibitions",
      component: () =>
        import(/* webpackChunkName: "Exhibitions" */ "./views/Exhibitions.vue"),
      beforeEnter: (to, from, next) => {
        if (store.state.exhibitions.length === 0) {
          store.dispatch("loadExhibitions").then(next);
        } else {
          next();
        }
      },
    },
    {
      path: "/bibliography",
      name: "bibliography",
      component: () =>
        import(
          /* webpackChunkName: "Bibliography" */ "./views/Bibliography.vue"
        ),
      beforeEnter: (to, from, next) => {
        if (store.state.bibliography.length === 0) {
          store.dispatch("loadBibliography", 0).then(next);
        } else {
          next();
        }
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
