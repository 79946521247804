import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const auth = {
  username: process.env.VUE_APP_USERNAME,
  password: process.env.VUE_APP_PASSWORD,
};

export default new Vuex.Store({
  state: {
    site: [],
    home: [],
    bibliography: [],
    about: [],
    conference: [],
    exhibitions: [],
    addresses: [],
    info: [],
  },
  getters: {
    getProjectByUID:
      (state) =>
      (uid, collection = state.projects) => {
        let i = collection.length;
        while (i--) {
          if (collection[i].uid === uid) {
            return collection[i];
          }
        }
      },
  },
  actions: {
    async loadSite({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "site",
            select: {
              title: true,
              url: true,
              menu: {
                query: "site.children.listed",
                select: {
                  title: true,
                  uid: true,
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_SITE", response.data.result);
        });
    },
    async loadBibliography({ commit }, payload) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('bibliography')",
            select: {
              items: {
                query: "page.children.listed",
                select: {
                  title: true,
                  uid: true,
                  author: true,
                  publisher: true,
                  year: true,
                  language: true,
                  serial: true,
                  place: true,
                  toggle: "page.toggle.toBool()",
                  text: "page.text.kirbytext",
                  images: {
                    query: "page.images",
                    select: {
                      url: "file.resize(1000).url",
                    },
                  },
                },
                pagination: {
                  page: payload,
                  limit: 50,
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_BIBLIOGRAPHY", response.data.result.items);
        });
    },
    async loadHome({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('home')",
            select: {
              title: true,
              uid: true,
              images: {
                query: "page.images",
                select: {
                  url: "file.url",
                  srcset: "file.srcset([400, 800, 1200, 1600, 2000])",
                  thumb: "file.resize(200).url",
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_HOME", response.data.result);
        });
    },
    async loadAbout({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('about')",
            select: {
              title: true,
              uid: true,
              text: "page.text.kirbytext",
              right: "page.right.kirbytext",
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_ABOUT", response.data.result);
        });
    },
    async loadAddresses({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('addresses')",
            select: {
              title: true,
              uid: true,
              addresses: {
                  query: "page.addresses.toStructure",
                  select: {
                        index: "structureItem.indexOf",
                        name: "structureItem.name",
                        subname: "structureItem.subname",
                        born: "structureItem.born.kirbytext",
                        death: "structureItem.death.kirbytext",
                        address: "structureItem.address.kirbytext",
                        bio: "structureItem.bio.kirbytext",
                        sources: "structureItem.sources.kirbytext",
                        location: {
                              query: "structureItem.location.yaml",
                              select: {
                                    lat: "item.lat",
                                    lon: "item.lon"
                              }
                        },
                        offset: "structureItem.offset.toBool",
                        portrait: {
                              query: "structureItem.portrait.toFile",
                              select: {
                                    url: "file.url",
                                    thumb: "file.resize(600).url",
                                    caption: "file.caption",
                              }
                        }
                  }
              }
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_ADDRESSES", response.data.result);
        });
    },
    async loadConference({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('conference')",
            select: {
              title: true,
              uid: true,
              text: "page.left.kirbytext",
              programmetext: "page.programmetext.kirbytext",
              programme: {
                query: "page.programme.toStructure",
                select: {
                  hour: "structureItem.hour",
                  speaker: "structureItem.speaker.kirbytext",
                  vimeo: "structureItem.vimeo",
                  still: {
                        query: "structureItem.still.toFile",
                        select: {
                              url: "file.url",
                              thumb: "file.resize(600).url",
                        }
                  }
                },
              },
              notes: "page.notes.kirbytext",
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_CONF", response.data.result);
        });
    },
    async loadExhibitions({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('exhibitions')",
            select: {
              title: true,
              uid: true,
              nextexpo: "page.nextexpo.kirbytext",
              exhibitions: {
                query: "page.exhibitions.toStructure",
                select: {
                  title: "structureItem.title",
                  description: "structureItem.description.kirbytext",
                  images: {
                        query: "structureItem.gallery.toFiles",
                        select: {
                              url: "file.url",
                              srcset: "file.srcset([400, 800, 1200, 1600, 2000])",
                              width: "file.width",
                              height: "file.height"
                        }
                  }
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_EXPOS", response.data.result);
        });
    },
    async loadInfo({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('info')",
            select: {
              title: true,
              uid: true,
              text: "page.text.kirbytext",
              right: "page.right.kirbytext",
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_INFO", response.data.result);
        });
    },
  },

  mutations: {
    SET_SITE(state, data) {
      state.site = data;
    },
    SET_BIBLIOGRAPHY(state, pages) {
      if (state.bibliography.pagination) {
        state.bibliography.pagination = pages.pagination;
        for (var i = 0; i < pages.data.length; i++) {
          state.bibliography.data.push(pages.data[i]);
        }
      } else {
        state.bibliography = pages;
      }
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
    SET_HOME(state, data) {
      state.home = data;
    },
    SET_ABOUT(state, data) {
      state.about = data;
    },
    SET_ADDRESSES(state, data) {
      state.addresses = data;
    },
    SET_CONF(state, data) {
      state.conference = data;
    },
    SET_EXPOS(state, data) {
      state.exhibitions = data;
    },
    SET_INFO(state, data) {
      state.info = data;
    },
  },
});
